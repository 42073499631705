import React from "react";

import {
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  EditButton,
  Filter,
  FormTab,
  FunctionField,
  List,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  TabbedForm,
  TextField,
  TextInput,
  required,
} from "react-admin";

import { getProfileTypeName } from "components/Accounts";
import YesNoField from "./fields/YesNoField";
import { validatePhoneNumber } from "../utils/validators";

export const panelStatusOptions = [
  { id: "DISARM", value: "Disarmed" },
  { id: "ARM_HOME", value: "Arm Home" },
  { id: "ARM_AWAY", value: "Arm Away" },
];

export const PanelFilter: React.FC = (props) => (
  <Filter {...props}>
    <TextInput label="Search" source="q" alwaysOn />
  </Filter>
);

export const PanelList: React.FC = (props) => (
  <List {...props} filters={<PanelFilter />} exporter={false}>
    <Datagrid>
      <TextField source="name" label="Panel name" />
      <TextField source="objectId" label="ID (Visonic)" />
      <TextField source="ajaxHubId" label="ID (Ajax)" />
      <TextField source="gsmNumber" />
      <ChipField source="status" />

      <YesNoField
        source="allowCalmManualDispatch"
        label="Allow Manual Dispatch"
      />

      <YesNoField
        source="autoDispatchInstantPanic"
        label="Automatic Dispatch for Panic and Emergency"
      />

      <YesNoField source="showYaleAccessLink" label="Show Link to Yale App" />

      <YesNoField source="homeModeEnabled" label="Home Mode Enabled" />

      <YesNoField
        source="zonesEnabled"
        label="Partitions for Ajax groups Enabled (does not work for Visonic)"
      />

      <ReferenceField label="Account" source="accountId" reference="Account">
        <TextField source="name" label="Account name" />
      </ReferenceField>

      <EditButton basePath="/Panel" />
    </Datagrid>
  </List>
);

export const PanelEdit: React.FC = (props) => (
  <Edit title="Update Panel" {...props}>
    <TabbedForm>
      <FormTab label="Basic Details">
        <TextInput source="id" disabled />

        <TextInput source="name" label="Panel name" />
        <TextInput source="objectId" label="ID (Visonic)" />
        <TextInput
          source="ajaxHubId"
          label="ID (Ajax)"
          parse={parseAjaxHubId}
          validate={validateAjaxHubId}
        />

        <SelectInput
          source="status"
          optionText="value"
          choices={panelStatusOptions}
        />

        <TextInput
          source="gsmNumber"
          label="GSM Number"
          validate={validatePhoneNumber}
        />

        <TextInput source="address" label="Street address" />
        <TextInput source="postcode" />
        <TextInput source="city" />

        <ReferenceInput label="Account" source="accountId" reference="Account">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <ReferenceField
          label="Account Response"
          source="accountId"
          reference="Account"
        >
          <FunctionField source="profileType" render={getProfileTypeName} />
        </ReferenceField>

        <ReferenceInput
          label="Response Service"
          source="responseServiceId"
          reference="ResponseService"
          validate={required()}
        >
          <SelectInput />
        </ReferenceInput>

        <BooleanInput
          source="allowCalmManualDispatch"
          label="Activate Guard Now button for the app"
        />
        <BooleanInput
          source="autoDispatchInstantPanic"
          label="Activate Automatic Dispatch for Panic and Emergency"
        />
        <BooleanInput
          source="showYaleAccessLink"
          label="Show Link to Yale App"
        />

        <TextInput
          source="tempIndoorSensorId"
          label="Indoor Temp Sensor"
          helperText="Ajax Device-ID (8 characters)"
        />

        <NumberInput
          source="tempAlarmIndoorTempMin"
          label="Temp Alarm - Indoor Min Value"
          helperText="Ajax only - the alarm event will trigger when crossing the temperature."
        />

        <NumberInput
          source="tempAlarmIndoorTempMax"
          label="Temp Alarm - Indoor Max Value"
          helperText="Ajax only - the alarm event will trigger when crossing the temperature."
        />

        <NumberInput
          source="tempAlarmOutdoorTempMin"
          label="Temp Alarm - Outdoor Min Value"
          helperText="The alarm event will trigger when crossing the temperature."
        />

        <NumberInput
          source="tempAlarmOutdoorTempMax"
          label="Temp Alarm - Outdoor Max Value"
          helperText="The alarm event will trigger when crossing the temperature."
        />

        <BooleanInput source="homeModeEnabled" label="Home Mode Enabled" />

        <BooleanInput
          source="zonesEnabled"
          label="Partitions for Ajax groups Enabled (does not work for Visonic)"
        />

        <BooleanInput
          source="photoOnDemandEnabled"
          label="Photo on demand for Ajax Enabled (does not work for Visonic)"
        />
      </FormTab>

      <FormTab label="Referees"></FormTab>
      <FormTab label="Events"></FormTab>
    </TabbedForm>
  </Edit>
);

export const PanelCreate: React.FC = (props) => (
  <Create title="Create a Panel" {...props}>
    <SimpleForm>
      <TextInput source="name" label="Panel name" />
      <TextInput source="objectId" label="ID (Visonic)" />
      <TextInput
        source="ajaxHubId"
        label="ID (Ajax)"
        parse={parseAjaxHubId}
        validate={validateAjaxHubId}
      />

      <SelectInput
        source="status"
        optionText="value"
        choices={panelStatusOptions}
      />

      <TextInput source="gsmNumber" label="GSM Number" />
      <TextInput source="address" label="Street address" />
      <TextInput source="postcode" />
      <TextInput source="city" />

      <BooleanInput
        source="photoOnDemandEnabled"
        label="Photo on demand for Ajax Enabled (does not work for Visonic)"
        defaultValue={true}
      />

      <ReferenceInput label="Account" source="accountId" reference="Account">
        <AutocompleteInput
          shouldRenderSuggestions={(val: string) => {
            return val.trim().length > 2;
          }}
          optionText="name"
        />
      </ReferenceInput>

      <ReferenceInput
        label="Response Service"
        source="responseServiceId"
        reference="ResponseService"
      >
        <SelectInput />
      </ReferenceInput>
    </SimpleForm>
  </Create>
);

// Helper function to help user input a correctly formed ajax hub id
const parseAjaxHubId = (v: string | undefined) =>
  v
    // Ajax hub ids are max 8 characters long
    ?.slice(0, 8)
    // Characters A-F are case insensitive
    .toUpperCase()
    // Only allow A-F and 0-9
    .replace(/[^A-F0-9]/g, "");

// Helper function to validate the ajax hub
const validateAjaxHubId = (v: string | undefined) => {
  // An ajax hub id is always 8 characters long
  if (v && v.length !== 8) {
    return "must be 8 characters long";
  }
  // The validation functions returns undefined if valid
  return undefined;
};
